import React from 'react';
import { Loader } from './styled';

export default function CodeTextarea({
  header,
  hint,
  isLoading,
  value,
}) {
  const copyCode = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <div className="popup__hint-header">{header}</div>
      <div className="popup__hint">{hint}</div>
      {isLoading && <Loader />}
      <textarea
        className="popup__textarea popup__textarea--hero"
        style={{ minHeight: '280px' }}
        rows="12"
        value={value}
        readOnly
      />
      <button
        className="popup__copy-button button--mobile-hero"
        onClick={copyCode}
        data-action="widget-tabs#showCopyNotice"
      >
        Copy Code
      </button>
    </>
  );
}
