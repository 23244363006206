import React from 'react';
import PropTypes from 'prop-types';

import ChatbotSection from './components/ChatbotSection';
import ProgressBarSection from './components/ProgressBarSection';
import HideBrandingSection from './components/HideBrandingSection';
import NotificationSection from './components/NotificationSection';
import CustomBrandingSection from './components/CustomBrandingSection';
import VideoSettingsSection from './components/VideoSettingsSection';
import SocialShareSection from './components/SocialShareSection';
import MultipleFieldsSection from './components/MultipleFieldsSection';
import CustomCssSection from './components/CustomCssSection';

function ExperienceSettings({ settings }) {
  return (
    <>
      <NotificationSection {...settings} />
      <ProgressBarSection {...settings} />
      <CustomBrandingSection {...settings} />
      <HideBrandingSection {...settings} />
      <SocialShareSection {...settings} />
      <VideoSettingsSection {...settings} />
      <ChatbotSection {...settings} />
      <MultipleFieldsSection
        defaultFields={settings.urlParams}
        sectionName="UrlParamsSection"
        panelTitle="Hidden Fields"
        header="Metadata parameters"
        buttonText="+ Add Parameter"
        inputMaxLength={32}
      />
      <CustomCssSection customCss={settings.customCss} />
    </>
  );
}

ExperienceSettings.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default ExperienceSettings;
